import { atom } from "jotai";

/**
 * SearchBar 버튼과 회원가입 버튼이 한 화면에 표시되지 않기를 바란다는 디자인 요구사항 대응
 */
const IS_REGISTRATION_BUTTON_VISIBLE_ATOM = atom<boolean>(false);

export default {
  IS_REGISTRATION_BUTTON_VISIBLE_ATOM,
};
