import { Fragment } from "react";

import FormStyled from "@sellernote/_sds-v2/src/components/form/index.styles";

import { RecommendationListType } from ".";
import Styled from "./index.styles";

type SearchResultOptionsProps<T> = {
  onSelect: (value: T) => void;
  recommendationList: RecommendationListType<T>;
};

export default function RecommendedList<T>({
  onSelect,
  recommendationList,
}: SearchResultOptionsProps<T>) {
  const handleSelect = (value: T) => () => {
    onSelect(value);
  };

  return (
    <FormStyled.optionList
      className="custom-option-list"
      labelPosition="top"
      optionListPosition="bottom"
      width="100%"
    >
      {Object.values(recommendationList).map(({ shipmentType, list }) => (
        <Fragment key={shipmentType}>
          <Styled.shipmentType>
            {shipmentType === "exportation" ? "수출" : "수입"}
          </Styled.shipmentType>

          {list.map(({ label, value }) => (
            <li key={String(value)} onClick={handleSelect(value)}>
              {label}
            </li>
          ))}
        </Fragment>
      ))}
    </FormStyled.optionList>
  );
}
