import styled from "styled-components";

import { COLOR } from "@sellernote/_sds-v2/src/styles/colors";
import { setFontStyle } from "@sellernote/_sds-v2/src/styles/typography";

const searchBar = styled.div<{ isLanding: boolean }>`
  width: 1240px;
  padding: 10px 16px 14px 16px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 24px;
  border-radius: 4px;
  background-color: ${({ isLanding }) =>
    isLanding ? "rgba(255, 255, 255, 0.20)" : COLOR.grayScale_800};

  // 디자인 요구사항
  .custom-label-color label {
    color: ${COLOR.wh};

    .required {
      ${setFontStyle("Body3")};
      color: ${COLOR.point_300};
    }
  }

  // 디자인 요구사항
  .custom-button-height {
    button {
      height: 48px;
    }
  }
`;

const partContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export default {
  searchBar,
  partContainer,
};
