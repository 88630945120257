import { FreightTypeOption } from "@sellernote/_shared/src/types/forwarding/schedule";

import { useSearch } from "../SearchContext";

const checkIsSeaFreightType = (freightType: FreightTypeOption) =>
  freightType === FreightTypeOption.FCL ||
  freightType === FreightTypeOption.LCL;

const checkIsAirFreightType = (freightType: FreightTypeOption) =>
  freightType === FreightTypeOption.Air;

export default function useFreightType() {
  const [{ freightType }, setSearch] = useSearch();

  const handleFreightTypeSelect = (selectedFreightType: FreightTypeOption) => {
    const isCurrentAirSelected = checkIsAirFreightType(freightType);
    const isCurrentSeaSelected = checkIsSeaFreightType(freightType);
    const isNewAirSelected = checkIsAirFreightType(selectedFreightType);
    const isNewSeaSelected = checkIsSeaFreightType(selectedFreightType);

    const isAirToSeaChanged = isCurrentAirSelected && isNewSeaSelected;
    const isSeaToAirChanged = isCurrentSeaSelected && isNewAirSelected;

    if (isAirToSeaChanged || isSeaToAirChanged) {
      setSearch({
        polId: null,
        podId: null,
        freightType: selectedFreightType,
      });
      return;
    }

    setSearch({ freightType: selectedFreightType });
  };

  return {
    freightType,
    handleFreightTypeSelect,
  };
}
