import { GET_USER_PORT_LIST_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/common";
import { UserPort } from "@sellernote/_shared/src/types/common/common";
import { ShipmentType } from "@sellernote/_shared/src/types/forwarding/common";
import { FreightTypeOption } from "@sellernote/_shared/src/types/forwarding/schedule";

import { RecommendationListType } from "./components/InputSearchForSchedule";

import {
  POD_CODE_RECOMMENDATION_LIST_FOR_AIR_EXPORTATION,
  POD_CODE_RECOMMENDATION_LIST_FOR_AIR_IMPORTATION,
  POD_CODE_RECOMMENDATION_LIST_FOR_SEA_EXPORTATION,
  POD_CODE_RECOMMENDATION_LIST_FOR_SEA_IMPORTATION,
  POL_CODE_RECOMMENDATION_LIST_FOR_AIR_EXPORTATION,
  POL_CODE_RECOMMENDATION_LIST_FOR_AIR_IMPORTATION,
  POL_CODE_RECOMMENDATION_LIST_FOR_SEA_EXPORTATION,
  POL_CODE_RECOMMENDATION_LIST_FOR_SEA_IMPORTATION,
} from "./data";

type LocationType = "pol" | "pod";

/**
 * (디자인 요청) 랜덤으로 5개 항목 노출
 */
const getRandomPortList = (arr: string[]) => {
  if (arr.length <= 5) {
    return arr;
  }

  return arr.sort(() => 0.5 - Math.random()).slice(0, 5);
};

const formatPort = ({
  name,
  nameC,
  nameEN,
}: {
  name: string | undefined;
  nameC: string | null | undefined;
  nameEN: string;
}) => {
  if (name) {
    if (nameC) {
      return `${name}(${nameC}) - ${nameEN}`;
    }

    return `${name} - ${nameEN}`;
  }

  // 한글명 없이 별칭만 있는 경우는 없음
  return nameEN;
};

const getOption = (port: UserPort) => ({
  label: formatPort({
    name: port.name,
    nameC: port.nameC,
    nameEN: port.nameEN,
  }),
  value: port.id,
});

const getOptionList = (list: GET_USER_PORT_LIST_RES | undefined) =>
  (list ?? []).map(getOption);

const getRecommendationCodeList = ({
  locationType,
  freightType,
  shipmentType,
}: {
  locationType: LocationType;
  freightType: FreightTypeOption;
  shipmentType: ShipmentType;
}) =>
  ({
    POL_CODE_RECOMMENDATION_LIST_FOR_SEA_EXPORTATION: getRandomPortList(
      POL_CODE_RECOMMENDATION_LIST_FOR_SEA_EXPORTATION
    ),
    POL_CODE_RECOMMENDATION_LIST_FOR_SEA_IMPORTATION: getRandomPortList(
      POL_CODE_RECOMMENDATION_LIST_FOR_SEA_IMPORTATION
    ),
    POL_CODE_RECOMMENDATION_LIST_FOR_AIR_EXPORTATION: getRandomPortList(
      POL_CODE_RECOMMENDATION_LIST_FOR_AIR_EXPORTATION
    ),
    POL_CODE_RECOMMENDATION_LIST_FOR_AIR_IMPORTATION: getRandomPortList(
      POL_CODE_RECOMMENDATION_LIST_FOR_AIR_IMPORTATION
    ),
    POD_CODE_RECOMMENDATION_LIST_FOR_SEA_EXPORTATION: getRandomPortList(
      POD_CODE_RECOMMENDATION_LIST_FOR_SEA_EXPORTATION
    ),
    POD_CODE_RECOMMENDATION_LIST_FOR_SEA_IMPORTATION: getRandomPortList(
      POD_CODE_RECOMMENDATION_LIST_FOR_SEA_IMPORTATION
    ),
    POD_CODE_RECOMMENDATION_LIST_FOR_AIR_EXPORTATION: getRandomPortList(
      POD_CODE_RECOMMENDATION_LIST_FOR_AIR_EXPORTATION
    ),
    POD_CODE_RECOMMENDATION_LIST_FOR_AIR_IMPORTATION: getRandomPortList(
      POD_CODE_RECOMMENDATION_LIST_FOR_AIR_IMPORTATION
    ),
  }[
    `${
      locationType.toUpperCase() as Uppercase<LocationType>
    }_CODE_RECOMMENDATION_LIST_FOR_${
      freightType === FreightTypeOption.Air ? "AIR" : "SEA"
    }_${shipmentType.toUpperCase() as Uppercase<ShipmentType>}`
  ]);

const getPortOptionListByCode = ({
  recommendationCodeList,
  portList,
}: {
  recommendationCodeList: string[];
  portList: GET_USER_PORT_LIST_RES | undefined;
}) =>
  recommendationCodeList.map((code) => {
    const port = portList?.find((port) => port.code === code);

    if (!port) {
      return { label: "", value: 0 };
    }

    return getOption(port);
  });

const getRecommendationList = ({
  portList,
  freightType,
  locationType,
  portCountryOfAnotherLocationType,
}: {
  portList: GET_USER_PORT_LIST_RES | undefined;
  freightType: FreightTypeOption;
  locationType: LocationType;
  portCountryOfAnotherLocationType: string | undefined;
}): RecommendationListType<number | null> => {
  const isAnotherLocationTypeSelected = Boolean(
    portCountryOfAnotherLocationType
  );

  /**
   * 다른 하나의 출발/도착지가 선택되어 있지 않은 경우에는 수출, 수입 순서로 추천 목록을 모두 보여준다.
   */
  if (!isAnotherLocationTypeSelected) {
    return [
      {
        shipmentType: "exportation",
        list: getPortOptionListByCode({
          recommendationCodeList: getRecommendationCodeList({
            locationType,
            freightType,
            shipmentType: "exportation",
          }),
          portList,
        }),
      },
      {
        shipmentType: "importation",
        list: getPortOptionListByCode({
          recommendationCodeList: getRecommendationCodeList({
            locationType,
            freightType,
            shipmentType: "importation",
          }),
          portList,
        }),
      },
    ];
  }

  /**
   * 다른 하나의 출발/도착지가 선택되어 있는 경우에는 선택된 port가 한국인지(수출/수입인지) 여부에 따라 해당하는 수출/수입 추천 목록을 보여준다.
   */
  const isLocationTypePol = locationType === "pol";
  const isAnotherLocationPortInKR = portCountryOfAnotherLocationType === "KR";

  if (isLocationTypePol) {
    if (isAnotherLocationPortInKR) {
      return [
        {
          shipmentType: "importation",
          list: getPortOptionListByCode({
            recommendationCodeList: getRecommendationCodeList({
              locationType,
              freightType,
              shipmentType: "importation",
            }),
            portList,
          }),
        },
      ];
    }

    return [
      {
        shipmentType: "exportation",
        list: getPortOptionListByCode({
          recommendationCodeList: getRecommendationCodeList({
            locationType,
            freightType,
            shipmentType: "exportation",
          }),
          portList,
        }),
      },
    ];
  }

  if (isAnotherLocationPortInKR) {
    return [
      {
        shipmentType: "exportation",
        list: getPortOptionListByCode({
          recommendationCodeList: getRecommendationCodeList({
            locationType,
            freightType,
            shipmentType: "exportation",
          }),
          portList,
        }),
      },
    ];
  }

  return [
    {
      shipmentType: "importation",
      list: getPortOptionListByCode({
        recommendationCodeList: getRecommendationCodeList({
          locationType,
          freightType,
          shipmentType: "importation",
        }),
        portList,
      }),
    },
  ];
};

/**
 * freightType은 default를 FCL로 설정했기 때문에, AIR, FCL, LCL 이외의 값이 들어올 수 없음
 */
const getPortType = (freightType: FreightTypeOption) =>
  freightType === FreightTypeOption.Air ? "air" : "sea";

export { getOptionList, getRecommendationList, getPortType };
