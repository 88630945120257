const POL_CODE_RECOMMENDATION_LIST_FOR_SEA_EXPORTATION = [
  /** 부산항 */
  "KRPUS",
  /** 인천항 */
  "KRINC",
];

const POL_CODE_RECOMMENDATION_LIST_FOR_SEA_IMPORTATION = [
  /** 상하이항 */
  "CNSHG",
  /** 호치민항 */
  "VNSGN",
  /** 함부르크항 */
  "DEHAM",
  /** 오사카항 */
  "JPOSA",
  /** 로스엔젤레스항 */
  "USLAX",
];

const POL_CODE_RECOMMENDATION_LIST_FOR_AIR_EXPORTATION = [
  /** 인천공항 */
  "ICN",
];

const POL_CODE_RECOMMENDATION_LIST_FOR_AIR_IMPORTATION = [
  /** 말펜사(밀라노) 공항 */
  "MXP",
  /** 푸동(상하이/상해) 공항 */
  "PVG",
  /** 프랑크푸르트암마인 공항 */
  "FRA",
  /** 로스앤젤레스 공항 */
  "LAX",
  /** 히스로(런던) 공항 */
  "LHR",
];

const POD_CODE_RECOMMENDATION_LIST_FOR_SEA_EXPORTATION = [
  /** 상하이항 */
  "CNSHG",
  /** 호치민항 */
  "VNSGN",
  /** 싱가포르항 */
  "SGSIN",
  /** 롱비치항항 */
  "USLGB",
  /** 뉴욕항 */
  "USNYC",
  /** 함부르크항 */
  "DEHAM",
];

const POD_CODE_RECOMMENDATION_LIST_FOR_SEA_IMPORTATION = [
  /** 부산항 */
  "KRPUS",
  /** 인천항 */
  "KRINC",
  /** 평택항 */
  "KRPTK",
  /** 광양항 */
  "KRKWA",
  /** 군산항 */
  "KRKUV",
];

const POD_CODE_RECOMMENDATION_LIST_FOR_AIR_EXPORTATION = [
  /** 푸동(상하이/상해) 공항 */
  "PVG",
  /** 노이바이(하노이) 공항 */
  "HAN",
  /** 수완나품(방콕) 공항 */
  "BKK",
  /** 창이(싱가포르/싱가폴) 공항 */
  "SIN",
  /** 로스앤젤레스 공항 */
  "LAX",
  /** 하츠필드 잭슨 애틀랜타 공항 */
  "ATL",
  /** 프랑크푸르트암마인 공항 */
  "FRA",
  /** 오헤어(시카고) 공항 */
  "ORD",
];

const POD_CODE_RECOMMENDATION_LIST_FOR_AIR_IMPORTATION = [
  /** 인천공항 */
  "ICN",
];

/** 추천목록은 랜덤으로 각 항목의 5개만 노출 시킨다. */
export {
  POL_CODE_RECOMMENDATION_LIST_FOR_SEA_EXPORTATION,
  POL_CODE_RECOMMENDATION_LIST_FOR_SEA_IMPORTATION,
  POL_CODE_RECOMMENDATION_LIST_FOR_AIR_EXPORTATION,
  POL_CODE_RECOMMENDATION_LIST_FOR_AIR_IMPORTATION,
  POD_CODE_RECOMMENDATION_LIST_FOR_SEA_EXPORTATION,
  POD_CODE_RECOMMENDATION_LIST_FOR_SEA_IMPORTATION,
  POD_CODE_RECOMMENDATION_LIST_FOR_AIR_EXPORTATION,
  POD_CODE_RECOMMENDATION_LIST_FOR_AIR_IMPORTATION,
};
