import { addDate } from "@sellernote/_shared/src/utils/common/date";
import Calendar from "@sellernote/_sds-v2/src/components/calendar/Calendar";

import { useSearch } from "../SearchContext";

export default function Eta({ isMobile = false }: { isMobile?: boolean }) {
  const [{ etd, eta }, setSearch] = useSearch();

  const handleEtaSelect = (selectedDate: string) => {
    setSearch({ eta: selectedDate });
  };

  return (
    <Calendar
      className="custom-label-color"
      size="default"
      type="datePicker"
      {...(!isMobile && { inputWidth: "161px" })}
      labelInfo={{ label: "ETA" }}
      date={eta ?? ""}
      setDate={handleEtaSelect}
      getMinDate={() =>
        addDate({
          date: etd,
          value: 1,
          unit: "day",
        })
      }
    />
  );
}
