import { RefObject, useMemo } from "react";

import COMMON_QUERY from "@sellernote/_shared/src/queries/forwarding/COMMON_QUERY";
import { InputSearchWithOptionsHandlerList } from "@sellernote/_sds-v2/src/components/form/InputSearchWithOptionsV2";

import { getOptionList, getPortType, getRecommendationList } from "../utils";

import { useSearch } from "../SearchContext";
import InputSearchForSchedule from "./InputSearchForSchedule";

export default function Pod({
  podOptionHandlerListRef,
  isMobile = false,
}: {
  podOptionHandlerListRef: RefObject<InputSearchWithOptionsHandlerList>;
  isMobile?: boolean;
}) {
  const [{ freightType, podId, polId }, setSearch] = useSearch();

  const { data: portList } = COMMON_QUERY.useGetUserPortList({
    portType: getPortType(freightType),
  });

  const podOptionList = useMemo(() => getOptionList(portList), [portList]);

  const podRecommendationList = useMemo(
    () =>
      getRecommendationList({
        portList,
        freightType,
        locationType: "pod",
        portCountryOfAnotherLocationType: portList?.find(
          (port) => port.id === polId
        )?.country,
      }),
    [freightType, polId, portList]
  );

  const handlePodSelect = (selectedPodId: number | null) => {
    setSearch({ podId: selectedPodId });
  };

  const handlePodReset = () => {
    setSearch({ podId: null });
  };

  return (
    <InputSearchForSchedule<number | null>
      className="custom-label-color"
      {...(!isMobile && { width: 296 })}
      labelInfo={{
        label: (
          <>
            도착지<span className="required">*</span>
          </>
        ),
        position: "top",
      }}
      placeholder="항구/공항을 입력해주세요."
      searchSourceList={podOptionList}
      selectedOptionValue={podId}
      onSelect={handlePodSelect}
      onReset={handlePodReset}
      recommendationList={podRecommendationList}
      inputSearchForScheduleHandlerList={podOptionHandlerListRef}
    />
  );
}
